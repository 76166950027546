<!-- eslint-disable -->
<template>
  <header class="chat-header">
    <div class="user-info-container">
      <img src="/defaultUser.png" class="selected-user-avatar" alt="" />
      <h4>{{ selectedUser.user }}</h4>
      <div class="factor-badge" :class="factorTextDynamicBackground">
        {{ factorText }}
      </div>
    </div>
    <div class="menu-button">
      <svg
        @click.stop="toggleMenu"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        style="width: 1.5rem; height: 1.5rem"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
        />
      </svg>

      <div v-click-outside="closeMenu" v-if="isMenuOpen" class="custom-menu">
        <ul class="custom-menu-list">
          <li class="menu-list-item" @click="renderNewFactorModal">
            ساخت فاکتور جدید
          </li>
          <hr class="divider" />
          <li class="menu-list-item" @click="closeConversation">بستن</li>
        </ul>
      </div>
    </div>
    <b-modal
      id="modal-factor"
      centered
      ok-title="ثبت"
      cancelTitle="انصراف"
      title="ساخت فاکتور پرداخت جدید"
      @ok="createNewFactor"
    >
      <b-container>
        <b-form-row>
          <b-col cols="12">
            <b-form-group label="قیمت (تومان)" label-for="price-input">
              <b-form-input
                v-model="factorPrice"
                placeholder="100,0000"
                id="price-input"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>
  </header>
</template>
<script>
export default {
  /* eslint-disable */
  components: {
    BModal,
    BContainer,
    BFormGroup,
    BFormInput,
    BFormRow,
    BCol,
  },
  directives: {
    // A custom directive to handle when
    // User click outside of my element
    clickOutside: {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  emits: ["closeConversation", "getConsultationList"],
  props: {
    selectedUser: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      factorPrice: null,
    };
  },
  methods: {
    createNewFactor() {
      consultHub.on("CreatePaymentFactorForAdminSuccess", (res) => {
        this.$bvModal.hide("modal-factor");
        this.factorPrice = null;
        this.$emit("getConsultationList");
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: "موفقیت آمیز بود!",
            icon: "CheckIcon",
            variant: "success",
            text: `فاکتور با موفقیت ساخته شد.`,
          },
        });
        consultHub.off("CreatePaymentFactorForAdminSuccess", null);
      });
      consultHub.invoke(
        "CreatePaymentForAdmin",
        +this.routeQuery,
        +this.factorPrice
      );
    },
    renderNewFactorModal() {
      this.$bvModal.show("modal-factor");
    },
    closeConversation() {
      this.$emit("closeConversation");
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  computed: {
    factorText() {
      if (this.selectedUser) {
        if (!this.selectedUser.lastFacotor) {
          return "فاکتوری ساخته نشده";
        } else if (
          this.selectedUser.lastFacotor &&
          this.selectedUser.lastFacotor.isPayed
        ) {
          return "پرداخت شده";
        } else {
          return "پرداخت نشده";
        }
      }
    },
    factorTextDynamicBackground() {
      if (this.selectedUser) {
        if (!this.selectedUser.lastFacotor) {
          return "no-factor";
        } else if (
          this.selectedUser.lastFacotor &&
          this.selectedUser.lastFacotor.isPayed
        ) {
          return "payed-factor";
        } else {
          return "unpayed-factor";
        }
      }
    },
    routeQuery() {
      if (
        this.$route.query &&
        this.$route.query.id &&
        this.$route.query.id != ""
      ) {
        return this.$route.query.id;
      }
      return null;
    },
  },
};
import consultHub from "@/libs/consultHub";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BModal,
  BContainer,
  BFormGroup,
  BFormInput,
  BFormRow,
  BCol,
} from "bootstrap-vue";
</script>
<style scoped>
.factor-badge {
  margin-left: 1rem;
  font-size: 12px;
  color: #ffffff;
  padding: 0.2rem 0.4rem;
  border-radius: 1rem;
}
.no-factor {
  background-color: #5a5a5a;
}
.payed-factor {
  background-color: rgb(0, 85, 0);
}
.unpayed-factor {
  background-color: rgb(197, 0, 0);
}
.divider {
  margin-bottom: 5px;
}
.menu-list-item:hover {
  transition: all 0.2s ease;
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
}
.menu-list-item {
  padding: 0.5rem;
  border-radius: 10px;
}

.custom-menu-list {
  list-style-type: none;
  padding: 0px;
}
.custom-menu {
  position: absolute;
  top: 0;
  right: 100%;
  min-width: 10rem;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.menu-button {
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease;
  position: relative;
}
.menu-button:hover {
  background-color: rgb(209, 209, 209);
  border-radius: 100%;
}
.user-info-container {
  display: flex;
  align-items: center;
}
.chat-header {
  z-index: 100 !important;
  width: 100%;
  background-color: #ffffff;
  padding: 9px 15px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
}
.selected-user-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  margin-right: 1rem;
}
p,
h4 {
  margin: 0;
}
</style>
