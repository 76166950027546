<!-- eslint-disable -->

import { FeatherIcon } from 'vue-feather-icons';
<template>
  <div class="contact-single" dir="rtl">
    <img class="admin-avatar" src="/defaultUser.png" alt="" />
    <div class="admin-details">
      <h5 class="admin-name">{{ userDetails.user }}</h5>
      <small class="last-message">
        <template v-if="userDetails.lastMessageType == 1">
          {{ userDetails.lastMessage }}
        </template>
        <template v-else>
          <feather-icon icon="FileIcon" size="18" />
        </template>
      </small>
    </div>
    <div class="conversation-details">
      <small class="consult-date">
        {{ new Date(userDetails.createDate).toLocaleDateString("fa-IR") }}
      </small>
      <small v-if="userDetails.unSeenCount > 0" class="unseen-count">
        {{ userDetails.unSeenCount }}
      </small>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.unseen-count {
  font-size: 12px;
  background-color: #d49100;
  color: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100rem !important;
}
.conversation-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto !important;
}
.contact-single {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 100%;
  padding: 7px 15px;
  cursor: pointer;
  transition: all 0.15s ease-in;
  white-space: nowrap;
}
.last-message {
  display: block;
  white-space: nowrap;
  width: 4.5rem;
  max-width: 4.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-single:hover {
  background-color: #52a3ff;
  color: #ffffff !important;
}
.contact-single:hover .admin-details .admin-name {
  color: #ffffff !important;
}
.admin-name {
  transition: all 0.15s ease-in;
  white-space: nowrap;
  width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}
.admin-details h5 {
  font-weight: 600;
  font-size: 16px !important;
}
.consult-date {
  margin-right: auto !important;
  align-self: flex-start;
}
p,
h2 {
  margin: 0;
}
.contact-single li:not(:first-child) {
  padding-top: 0.7rem;
}
</style>
