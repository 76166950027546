<!-- eslint-disable -->
<template>
  <b-overlay :show="showOverlay" rounded="sm">
    <b-card no-body>
      <b-row no-gutters>
        <b-col cols="12" no-gutters>
          <main-conversation></main-conversation>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
/* eslint-disable */

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BModal,
} from "bootstrap-vue";
import MainConversation from "../../components/Consult/MainConversation.vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
// import { CouponGetAllRequest } from "@/libs/Api/Coupon";
// import { CouponDeleteRequest } from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";
// import { NumberGetAllRequest } from "@/libs/Api/Number";
// import { ConsultantRequestGet } from "@/libs/Api/ConsultantRequest";

export default {
  name: "NumbersList",
  title: "لیست درخواست‌ها - پنل ادمین مکس",
  data() {
    return {
      coupons: null,
      numbers: [],
      showOverlay: false,
      totalCount: null,
      addNewCategory: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      pageNumber: 1,
      count: 10,
      searchCommand: "",
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        { key: "phoneNumber", label: "شماره موبایل" },
        { key: "createDate", label: "تاریخ ثبت" },
      ],
      defaultCreateData: {
        isDeleted: false,
        productCategoryId: 0,
        parentId: 0,
        name: "",
      },
    };
  },
  async created() {
    // await this.getAllNumbers(this.pageNumber, this.count, this.searchCommand);
  },
  methods: {
    async getAllNumbers(pageNumber, count, searchCommand) {
      let _this = this;

      _this.showOverlay = true;

      let data = {
        page: pageNumber,
        size: count,
      };

      let consultantRequestGet = new ConsultantRequestGet(_this);
      consultantRequestGet.setParams(data);
      await consultantRequestGet.fetch(
        function (content) {
          _this.numbers = content.data.data;
          _this.totalCount = content.data.totalCount;
          _this.showOverlay = false;
        },
        function (error) {
          _this.showOverlay = false;
          console.log(error);
        }
      );
    },
    async deleteCoupon(param) {
      let _this = this;

      let couponDeleteRequest = new CouponDeleteRequest(_this);
      couponDeleteRequest.setId(param);
      await couponDeleteRequest.fetch(
        function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: "bottom-center",
            props: {
              title: `عملیات موفق`,
              icon: "CheckIcon",
              variant: "success",
              text: `کوپن حذف شد.`,
            },
          });
          _this.getAllNumbers(
            _this.pageNumber,
            _this.count,
            _this.searchCommand
          );
        },
        function (error) {
          console.log(error);
        }
      );
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR");
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0);
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param;
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getAllNumbers(nv, this.count, this.searchCommand);
    },
    searchCommand: function (nv, ov) {
      this.getAllNumbers(this.currentPage, this.count, nv);
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    MainConversation,
    vSelect,
  },
};
</script>

<style scoped></style>
