<!-- eslint-disable -->
<template>
  <div @click="renderChatMenu" class="start-consult-area">
    <div class="start-consult-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56px"
        height="56px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-message-square"
      >
        <path
          data-v-5e8ea5c2=""
          d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
        ></path>
      </svg>
    </div>
    <h4 class="start-consult-text d-none d-md-block">
      یک مشاوره جهت چت انتخاب کنید
    </h4>
    <h4 class="start-consult-text d-md-none">جهت انتخاب چت کلیک کنید</h4>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  emits: ["renderChatMenu"],
  methods: {
    renderChatMenu() {
      this.$emit("renderChatMenu");
    },
  },
};
</script>
<style scoped>
.start-consult-area {
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.start-consult-icon {
  border-radius: 50%;
  padding: 1.7rem;
  background: #fff;
  -webkit-box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12) !important;
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12) !important;
  background: #fff;
  color: #6e6b7b;
  margin-bottom: 1rem;
}
.start-consult-text {
  padding: 0.5rem 1rem;
  border-radius: 1.428rem;
  cursor: pointer;
  background: #fff;
  -webkit-box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12) !important;
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12) !important;
  color: #6e6b7b;
  font-size: 1.1rem !important;
  font-weight: 600;
}
</style>
