import WebServiceRequest from "../Api/WebServiceRequest";


class UploadFileForConsultation extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("PrivateConsultation/UploadFile");
    }

    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

export {UploadFileForConsultation};
