<!-- eslint-disable -->
<template>
  <div class="main-container" dir="rtl">
    <sidebar-menu
      ref="sidebarMenu"
      class="consult-sidebar"
      :class="isRenderingChatSidebar ? 'render-chat' : ''"
      @closeSidebarMenu="closeSidebarMenu"
      @getSelectedUser="setSelectedUser"
    ></sidebar-menu>
    <div class="main-chat-container" ref="chatContainer">
      <start-consult-button
        v-if="!selectedUser"
        @renderChatMenu="renderChatMenu"
      ></start-consult-button>
      <template v-else>
        <template v-if="isAdminJoined">
          <chat-header
            :selectedUser="selectedUser"
            @getConsultationList="reFetchConsultationList"
            @closeConversation="closeConversation"
          ></chat-header>
          <div class="inner-chat-container" dir="rtl" ref="innerChatContainer">
            <template v-for="(message, index) in messagesList">
              <p
                v-if="message.type == 1"
                dir="auto"
                :key="index"
                class="single-message"
                :class="handleMessageClasses(message, index)"
                ref="message"
              >
                {{ message.message }}
              </p>
              <div
                v-else-if="message.type == 5"
                class="single-message-media"
                ref="message"
                dir="auto"
                :key="index + messagesList.length"
                :class="handleMessageClasses(message, index)"
              >
                <img
                  style="
                    width: 8rem;
                    height: 8rem;
                    object-fit: contain;
                    padding-top: 0.5rem;
                  "
                  :src="handleMediaUrl(message.message)"
                />
                <a
                  :href="handleMediaUrl(message.message)"
                  target="_blank"
                  download
                >
                  <svg
                    :style="message.isMe ? 'color: #ffffff;' : 'color: #000000'"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    style="
                      width: 1.5rem !important;
                      height: 1.5rem !important;
                      min-width: 1.5rem;
                      min-height: 1.5rem;
                      margin-left: 1rem;
                    "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                </a>
              </div>
              <div
                :key="index + messagesList.length + messagesList.length"
                class="single-message"
                ref="message"
                :class="handleMessageClasses(message, index)"
                v-else
              >
                <a
                  :href="handleMediaUrl(message.message)"
                  target="_blank"
                  download
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="width: 3rem; height: 3rem"
                  >
                    <path
                      d="M16 2L21 7V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918C3 2.44405 3.44495 2 3.9934 2H16ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8H8V16H12ZM10 10H12C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14H10V10Z"
                      fill="rgba(75,75,75,1)"
                    ></path>
                  </svg>
                </a>
              </div>
            </template>
          </div>
          <chat-footer ref="chatFooter"></chat-footer>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import consultHub from "@/libs/consultHub";
import StartConsultButton from "./StartConsultButton.vue";
import SidebarMenu from "./SidebarMenu.vue";
import ChatHeader from "./ChatHeader.vue";
import ChatFooter from "./ChatFooter.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";

export default {
  components: {
    StartConsultButton,
    SidebarMenu,
    ChatHeader,
    ChatFooter,
    ChatFooter,
  },
  watch: {
    routeQuery: {
      immediate: true,
      handler(val) {
        if (val) {
          this.messageList = null;
        }
      },
    },
  },

  data() {
    return {
      isRenderingChatSidebar: false,
      selectedUser: null,
      messagesList: null,
      isAdminJoined: false,
    };
  },
  methods: {
    handleMediaUrl(mediaUrl) {
      return Helper.baseUrl + mediaUrl;
    },
    handleMessageClasses(message, index) {
      let classList = message.isMe ? " from-me" : " from-them";
      if (this.messagesList && this.messagesList[index + 1]) {
        if (
          this.messagesList[index + 1].isMe != this.messagesList[index].isMe
        ) {
          classList += " margin-b_low";
        } else {
          classList += " no-tail";
        }
      }
      return classList;
    },
    async reFetchConsultationList() {
      await this.$refs.sidebarMenu.getConsultationList();
    },
    async activateHubListeners() {
      // Get The whole history
      consultHub.on("MessagesList", (res) => {
        this.messagesList = res.messageList;
        this.$refs.sidebarMenu.setUnseenCountToZero();
      });

      // Notify when payment is done
      consultHub.on("PaymentSuccessByUser", async (res) => {
        if (res.id == this.routeQuery) {
          await this.$refs.sidebarMenu.getConsultationList();
          this.$toast({
            component: ToastificationContent,
            position: "bottom-center",
            props: {
              title: "پرداخت!",
              icon: "CheckIcon",
              variant: "success",
              text: `فاکتور این مشاوره پرداخت شد.`,
            },
          });
        }
      });

      // Get New Messages
      consultHub.on("NewMessage", async (res) => {
        if (res.id == this.routeQuery) {
          this.messagesList.push(res);
        } else {
          await this.$refs.sidebarMenu.getConsultationList();
        }
        this.scrollToBottom();
      });

      // Successfully send new message
      consultHub.on("MessageSuccess", (res) => {
        this.messagesList.push(res);
        this.$refs.chatFooter.clearMessageInput();
        this.scrollToBottom();
      });

      // Join Successfully
      consultHub.on("JoinSuccess", (res) => {
        this.isAdminJoined = true;
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.message) {
          this.$refs.message[this.$refs.message.length - 1].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      });
    },
    closeConversation() {
      this.$router.push({ path: this.$route.path });
      this.selectedUser = null;
    },
    renderChatMenu() {
      this.isRenderingChatSidebar = true;
    },
    closeSidebarMenu() {
      this.isRenderingChatSidebar = false;
    },
    async removeHubListeners() {
      await consultHub.off("MessagesList", null);
      await consultHub.off("NewMessage", null);
      await consultHub.off("MessageSuccess", null);
      await consultHub.off("JoinSuccess", null);
      await consultHub.off("PaymentSuccessByUser", null);
    },
    async getMessageList() {
      try {
        await consultHub.invoke("GetMessagesForAdmin", this.selectedUser.id);
      } catch (error) {
        console.error(error);
      }
    },
    async joinConversationForAdmin() {
      try {
        await consultHub.invoke("Join", +this.routeQuery);
      } catch (error) {
        console.error(error);
      }
    },
    async setSelectedUser(user) {
      if (user != this.selectedUser) {
        this.messagesList = [];
        await this.removeHubListeners();
        await this.activateHubListeners();
        this.isAdminJoined = false;
        await this.joinConversationForAdmin();
        this.selectedUser = user;
        await this.getMessageList();
        this.scrollToBottom();
      }
    },
  },
  computed: {
    routeQuery() {
      if (
        this.$route.query &&
        this.$route.query.id &&
        this.$route.query.id != ""
      ) {
        return this.$route.query.id;
      }
      return null;
    },
  },
  beforeDestroy() {
    this.removeHubListeners();
  },
};
</script>

<style scoped>
.single-message-media {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  unicode-bidi: plaintext;
  text-align: start; /* Align text automatically left/right*/
  border-radius: 1.15rem;
  line-height: normal;
  max-width: 10rem;
  object-fit: contain;
  max-height: 10rem;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
  margin: 0.5rem 0;
  width: fit-content;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0px 5px 15px -3px rgba(0, 0, 0, 0.1);
}
.single-message-media::after,
.single-message-media::before {
  bottom: -0.1rem;
  content: "";
  position: absolute;
}
.inner-chat-container {
  height: 100%;
  min-height: auto;
  max-height: calc(100vh - 17rem);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.inner-chat-container::-webkit-scrollbar {
  display: none; /* Google Chrome*/
}

.single-message {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
  margin: 0.5rem 0;
  width: fit-content;
  font-size: 13px;
  font-weight: 500;
}

.single-message::after,
.single-message::before {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

.from-me {
  align-self: flex-start;
  background-color: #248bf5;
  color: #fff;
}

.from-me::after {
  background: linear-gradient(
    306deg,
    rgba(36, 139, 245, 1) 49%,
    rgba(255, 255, 255, 0) 55%
  );
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-23px, 0px);
  width: 10px;
}

.from-me ~ .from-me:last-child {
  margin-bottom: 0.5rem;
}

.no-tail::before,
.no-tail::after {
  display: none !important;
}

.margin-b_low {
  margin-bottom: 0.3rem !important;
}

.margin-b_one {
  margin-bottom: 1rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}

.from-them {
  align-self: flex-end;
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}

.from-them::before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  /* border-left: 1rem solid #e5e5ea; */
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

.from-them::after {
  background: linear-gradient(
    64deg,
    rgba(229, 229, 234, 1) 49%,
    rgba(255, 255, 255, 0) 55%
  );
  border-bottom-left-radius: 5rem;
  border-top-right-radius: 5rem;
  right: -40px;
  transform: translate(-36px, 0px);
  width: 15px;
}

.main-chat-container {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none !important;
  /* background-image: url("/chat-bg.svg"); */
  background-color: #fff;
  background-repeat: repeat;
  background-size: 210px;
  position: relative;
  width: 100%;
  min-width: 80%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.main-chat-container::-webkit-scrollbar {
  display: none; /* Google Chrome*/
}

.main-container {
  height: calc(100vh - 8rem);
  display: flex;
  position: relative;
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .consult-sidebar {
    position: absolute;
    left: -200%;
    /* transform: translateX(-50%); */
    top: 0;
    z-index: 10 !important;
    transition: all 0.5s ease-in-out;
  }

  .main-chat-container {
    display: block;
  }

  .render-chat {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and(min-width: 769px) {
  .consult-sidebar {
    position: relative;
    max-width: 20% !important;
  }

  .main-chat-container {
    display: flex;
    align-items: start;
  }
}

.unique {
  position: relative;
}

.unique::after {
  content: "test";
  position: absolute;
  top: 0;
  left: 0;
  color: black;
}
</style>
