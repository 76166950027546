<!-- eslint-disable -->
<template>
  <div class="sidebar-content">
    <div class="sidebar-overlay" v-if="isLoading">
      <div class="custom-spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <div class="aside-header">
      <div class="new-consult-section">
        <h2 class="head-title">لیست مشاوره های شما</h2>
      </div>
      <svg
        @click="closeSidebarMenu"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="close-icon"
      >
        <path
          d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
        />
      </svg>
    </div>
    <div>
      <ul class="consults-conversations">
        <li
          v-for="user in usersList"
          :key="user.id"
          @click="selectUser(user)"
          class="users-list"
        >
          <contact-card
            :class="routeQuery == user.id ? 'selected-user' : ''"
            :userDetails="user"
          ></contact-card>
        </li>
      </ul>
    </div>
    <!-- <base-modal
      :isRenderingModal="isRenderingNewConsultModal"
      @closeModal="closeNewConsultModal"
    >
      <template #title>ثبت مشاوره جدید</template>
      <template>
        <label style="font-size: 15px !important" for="textarea">
          متن پیام
        </label>
        <textarea id="textarea" v-model="firstMessage"></textarea>
        <div class="actions">
          <button
            @click="createNewPrivateConsultation"
            type="button"
            class="btn btn-success"
          >
            ثبت درخواست
          </button>
        </div>
      </template>
    </base-modal> -->
  </div>
</template>
<script>
/* eslint-disable */
// import { CreatePrivateConsultation } from "../../plugins/classes/Api/PrivateConsultation";
import ContactCard from "./ContactCard.vue";
// import BaseModal from "../Base/BaseModal.vue";
import { mapGetters } from "vuex";
import Helper from "@/libs/Helper";
import consultHub from "@/libs/consultHub";
export default {
  components: {
    ContactCard,
  },
  props: {
    selectedUser: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    getConnectionState: {
      immediate: true,
      async handler(val) {
        if (val) {
          // Invoke methods only if socket is
          // In connected state
          try {
            this.activateHubListeners();
            await this.getConsultationList();
          } catch (err) {
            console.error(err);
          }
        }
      },
    },
    routeQuery: {
      handler(val) {
        this.emitSelectedUser();
      },
    },
  },
  data() {
    return {
      usersList: null,
      firstMessage: null,
      isRenderingNewConsultModal: false,
      isLoading: true,
    };
  },
  emits: ["closeSidebarMenu", "getSelectedUser"],
  methods: {
    setUnseenCountToZero() {
      let userObject = this.usersList.find(
        (user) => user.id == this.routeQuery
      );
      if (userObject) {
        userObject.unSeenCount = 0;
        this.$emit("getSelectedUser", userObject);
      }
      consultHub.invoke("SeenForAdmin", +this.routeQuery);
    },
    emitSelectedUser() {
      let userObject = this.usersList.find(
        (user) => user.id == this.routeQuery
      );
      if (userObject) {
        this.$emit("getSelectedUser", userObject);
        this.closeSidebarMenu();
      }
    },
    renderNewConsultModal() {
      this.isRenderingNewConsultModal = true;
    },
    closeNewConsultModal() {
      this.isRenderingNewConsultModal = false;
    },

    activateHubListeners() {
      consultHub.on("NewConsult", (res) => {
        this.getConsultationList();
      });
      consultHub.on("ConsultationList", (res) => {
        this.usersList = res;
        this.isLoading = false;
      });
      consultHub.on("ConsultationListFailed", () => {
        this.getConsultationList();
      });
    },
    async getConsultationList() {
      await consultHub.invoke("GetConsultationListForAdmin");
      this.emitSelectedUser();
    },
    closeSidebarMenu() {
      this.$emit("closeSidebarMenu");
    },
    selectUser(user) {
      // Set Route query to trigger watcher
      // And emit user Object
      this.$router.push({
        path: this.$route.path,
        query: {
          id: user.id,
        },
      });
    },
    removeHubListeners() {
      consultHub.off("ConsultationList", null);
      consultHub.off("ConsultationListFailed", null);
      consultHub.off("NewConsult", null);
    },
  },
  computed: {
    // Connection state of consult hub
    ...mapGetters("consultSocketState", ["getConnectionState"]),
    isRouteQueryAndUserListAvailable() {
      if (this.routeQuery && this.usersList) {
        return true;
      }
      return false;
    },
    routeQuery() {
      if (
        this.$route.query &&
        this.$route.query.id &&
        this.$route.query.id != ""
      ) {
        return this.$route.query.id;
      }
      return null;
    },
  },
};
</script>
<style scoped>
.custom-spinner {
  width: 70px;
  text-align: center;
}

.custom-spinner > div {
  width: 15px;
  height: 15px;
  background-color: #0184ac;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.custom-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.custom-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sidebar-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #86868686;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-user {
  background-color: #227de6;
  color: #ffffff !important;
}
.selected-user >>> .admin-details .admin-name {
  color: #ffffff !important;
}
:deep(.child-class) {
  background-color: #000;
}
.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.new-consult-btn {
  font-size: 13px;
  padding: 0.2rem 0.4rem;
  border-radius: 0.3rem;
  background-color: #009c00;
  color: #ffffff;
  margin-right: 1rem;
  cursor: pointer;
}
.new-consult-section {
  display: flex;
  align-items: center;
}
.head-title {
  font-size: 17px;
}
.sidebar-content {
  overflow-y: auto;
  width: 98%;
  margin: 0 auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #ffffff;
  border: 1px #d1d1d1 solid;
  border-radius: 5px;
  height: 100%;
  min-height: calc(100vh - 116px) !important;
  position: relative;
}
.close-icon {
  width: 1.5rem;
  height: 1.5rem;
}
@media only screen and (min-width: 769px) {
  .close-icon {
    display: none;
  }
  .sidebar-content {
    overflow-y: auto;
    width: 40vw;
    height: 100%;
    border-radius: 0;
    border: 1px #d1d1d1 solid;
  }
}
.search-input-area {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  border: 1px solid #d1d1d1;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.search-icon {
  color: #d1d1d1;
}
.search-input {
  padding: 0;
  width: 100%;
  min-height: auto;
  color: inherit;
  border: none;
  outline: 0;
  background-color: #ffffff;
}
.search-button {
  background-color: inherit;
  border: none;
}
.search-input:focus {
  outline: none;
  border: none;
}
.aside-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d17e;
}
.consults-conversations {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  height: 100%;
}
.users-list:not(:last-child) {
  border-bottom: 1px #d1d1d183 solid;
}
h2 {
  margin: 0;
}
textarea {
  min-height: 14rem;
  height: 14rem;
}
</style>
