<!-- eslint-disable -->
<template>
  <div class="footer-container">
    <label class="button" @click="sendNewMessage">
      <feather-icon icon="SendIcon" size="20" />
    </label>
    <label class="button" @click="openAttachment">
      <feather-icon icon="PaperclipIcon" size="20" />
    </label>
    <textarea
      v-model.trim="message"
      rows="1"
      class="chat-input"
      placeholder="پیام شما"
    ></textarea>
    <input
      @input="handleFile"
      type="file"
      ref="attachmentInput"
      class="attachmentInput"
    />
    <b-modal
      id="modal-upload"
      centered
      title="ارسال فایل"
      ok-title="آپلود فایل"
      cancelTitle="انصراف"
      @cancel="closeMediaModal"
      @ok="uploadFileForConsultation"
    >
      <b-media class="my-2 col-12 mx-auto">
        <template>
          <b-avatar
            v-if="fileType == 'image'"
            class="w-100 cursor-pointer mx-auto"
            ref="previewEl"
            :src="base64File"
            size="300px"
            rounded
          >
          </b-avatar>
          <div v-else class="file-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="file-icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
              />
            </svg>
            <p>{{ fileName }}</p>
          </div>
        </template>
      </b-media>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable */
// import { UploadFileForConsultation } from "../../plugins/classes/Api/PrivateConsultation";
import consultHub from "@/libs/consultHub";
import { UploadFileForConsultation } from "@/libs/Api/PrivateConsultation";
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BOverlay,
  BModal,
  BMedia,
  BFormTextarea,
} from "bootstrap-vue";
import Helper from "@/libs/Helper";

export default {
  components: {
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    BModal,
    BMedia,
    BFormTextarea,
  },
  data() {
    return {
      message: null,
      base64File: null,
      fileName: null,
      isRenderingMediaModal: false,
      fileType: null,
      fileEnum: null,
    };
  },
  methods: {
    closeMediaModal() {
      this.$bvModal.hide("modal-upload");
      this.isRenderingMediaModal = false;
    },
    getFileType(file) {
      if (file.type.match("image.*")) {
        this.fileEnum = 5;
        return "image";
      }
      if (file.type.match("pdf.*")) {
        this.fileEnum = 6;
        return "pdf";
      }
    },
    sendFile(fileUrl) {
      try {
        // First number is conversation id
        // The second one is message type
        // Types:
        //  Text = 1,
        //  Video = 2,
        //  Voice = 3,
        //  Zip = 4,
        // Image = 5
        // PDF = 6
        consultHub.invoke(
          "MessageForAdmin",
          +this.routeQuery,
          fileUrl,
          +this.fileEnum
        );
      } catch (error) {
        console.error(error);
      }
    },
    handleFile(event) {
      if (event.target.files && event.target.files[0]) {
        this.fileType = this.getFileType(event.target.files[0]);
        this.fileName = event.target.files[0].name;
        Helper.createFileAsBase64Image(event.target.files[0], (base64File) => {
          this.base64File = base64File;
          this.isRenderingMediaModal = true;
          this.$bvModal.show("modal-upload");
        });
      }
    },
    clearMessageInput() {
      this.message = null;
    },
    sendNewMessage() {
      if (this.message && this.message != "") {
        try {
          // First number is conversation id
          // The second one is message type
          // Types:
          //  Text = 1,
          //  Video = 2,
          //  Voice = 3,
          //  Zip = 4
          //  Image = 5
          consultHub.invoke(
            "MessageForAdmin",
            +this.routeQuery,
            this.message,
            1
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    openAttachment() {
      this.$refs.attachmentInput.click();
    },

    async uploadFileForConsultation() {
      let _this = this;
      try {
        let uploadFileForConsultation = new UploadFileForConsultation(_this);
        uploadFileForConsultation.setParams({
          filename: _this.fileName,
          fileBase64: _this.base64File,
        });
        await uploadFileForConsultation.fetch((res) => {
          _this.closeMediaModal();
          _this.sendFile(res.data);
        });
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
  },

  computed: {
    routeQuery() {
      if (
        this.$route.query &&
        this.$route.query.id &&
        this.$route.query.id != ""
      ) {
        return this.$route.query.id;
      }
      return null;
    },
  },
};
</script>
<style scoped>
.file-container {
  display: flex;
  align-items: center;
}
.file-icon {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}
.attachmentInput {
  display: none;
}

.footer-container {
  background-color: #f3f3f3;
  padding: 1rem 0.7rem;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.chat-input {
  width: 100% !important;
  padding: 0.7rem 1.2rem;
  width: 100%;
  min-height: inherit;
  border-radius: 10rem;
  outline: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
  resize: none;
  scrollbar-width: none !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  transition: all 0.13s ease-in-out;
}

.chat-input::-webkit-scrollbar {
  display: none; /* Google Chrome*/
}

.button {
  margin: 0;
  margin-right: 1rem;
  cursor: pointer;
}

.send-icon {
  color: #248bf5;
  font-size: 18px !important;
}

.attach-icon {
  color: #248bf5;
  font-size: 18px !important;
}
</style>
